import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"


//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CadastroEmail from "../components/cadastro"
import {MetaData} from "../components/meta"

const NoticiaItem = ({ post }) => (
  <div class="row">
    {post.feature_image ? (
      <div class="col-md-3 col-sm-12">
        <Link to={`/noticias/${post.slug}`}>
          <img src={post.feature_image} alt={post.title} class="img-fluid" />
        </Link>
      </div>
    ) : null}

    <div class={post.feature_image ? "col-md-9" : "col-md-12"}>
      <h6 className="tag-title">{post.published_at_pretty}</h6>
      <Link className="text-reset" to={`/noticias/${post.slug}`}>
        <h5>{post.title}</h5>
      </Link>
      <Link className="text-reset" to={`/noticias/${post.slug}`}>
        <p>
          {post.excerpt.replace(/ *\[[^)]*\] */g, "").substr(0, 170) + "..."}
        </p>
      </Link>
    </div>
    <hr className="w-100 mx-3" />
  </div>
)

const NoticiasPage = ({ data,location }) => {
  const noticias = data.allGhostPost.edges
  const video = data.allYoutubeVideo.edges[0]
  return (
    <Layout>
     <MetaData data={data} location={location} type="series" />
           
      {/* noticia principal */}
      <div className="row">
        <div className="col-12">
          <h2>#notícias</h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-8">
          {noticias.map(({ node }) => (
            <NoticiaItem post={node} />
          ))}
        </div>
        <div className="col-sm-4 col-md-4 col-lg-4 bg-light p-4 rounded-lg ">
          <h2 className="">
            <strong>
              <small className="text-danger">
                <i>
                  {/* <FontAwesomeIcon icon={["fab", "youtube"]} /> */}
                </i>
              </small>{" "}
              vídeo
            </strong>
          </h2>
          <div className="col-12 mt-4 ">
            <Link className="" to={`videos/${video.node.slug}`}>
              <small>
                <strong>
                  <p className="text-left small strong">
                    {video.node.publishedAt}
                  </p>
                </strong>
              </small>
              <img
                className="img-fluid"
                src={video.node.thumbnails.high.url}
                alt={video.node.title}
              />
              <h5 className="text-left font-weight-bold mt-3">
                {video.node.title}
              </h5>

              <p className="mt-3 ">{video.node.description}</p>
            </Link>
            <hr className="w-100" />

            <Link className="text-reset " to="/noticias">
              <p className="font-weight-bold text-center text-danger">
                <strong>mais vídeos</strong>
              </p>
            </Link>
          </div>
          <div className="col-12 mt-4 ">
            <CadastroEmail />
          </div>
        </div>
      </div>

      {/* barra lateral */}
      {/* <div className="col-md-3">
        <div className="row">
          <p className="col-6 barra">Mais lidas</p>
        </div>
        <div className="row">
          <h6>Tema 3</h6>
          <h5>Lorem ipsum dolor sit amet</h5>
          <p>Lorem ipsum dolor sit amet Lorem ipsum dolor sit ametLorem ipsum dolor sit
            ametLorem ipsum dolor sit amet</p>
        </div>
        <div className="row">
          <h6>Tema 3</h6>
          <h5>Lorem ipsum dolor sit amet</h5>
          <p>Lorem ipsum dolor sit amet Lorem ipsum dolor sit ametLorem ipsum dolor sit
            ametLorem ipsum dolor sit amet</p>
        </div>
        <div className="row">
          <p className="col-6 barra d-flex align-items-center">Twitter</p>
          <Twitter limitPosts={2} />
        </div>
      </div> */}
    </Layout>
  )
}
export default NoticiasPage

// This page query loads all posts sorted descending by published date The
// `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostNoticiasQuery {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: 100
      filter: {
        slug: { ne: "data-schema" }
        tags: { elemMatch: { slug: { eq: "noticias" } } }
      }
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    allYoutubeVideo(sort: { order: DESC, fields: publishedAt }, limit: 1) {
      edges {
        node {
          ...YoutubeVideoFields
        }
      }
    }
  }
`
